import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  InputGroup,
  Input,
} from "reactstrap";
import { ConfigContext } from "../../context/ConfigContext"

var moment = require("moment");
const Header = (props) => {
  const { config } = React.useContext(ConfigContext);
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [name, setName] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  
  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8" style={{ background: config.headerBgColor }}>
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <div className="col-md-3 col-lg-3 col-sm-auto">
                <label
                  htmlFor="search"
                  style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                >
                  <b>Search by appointment ID</b>
                </label>
              </div>
              <div className="col-md-3 col-lg-3 col-sm-auto">
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Search by appointment ID"
                    type="text"
                    style={{ width: "auto" }}
                    onChange={(e) => setAppointmentId(e.target.value)}
                    value={appointmentId}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        props.appointmentIdSearch(e.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </div>

              <div className="col-md-2 col-lg-2 searchButton">
                <Button
                  color="default"
                  onClick={props.appointmentIdSearch.bind(null, appointmentId)}
                  size="l"
                >
                  Search
                </Button>
              </div>
            </Row>
            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

            <Row>
              <div className="col-md-3 col-lg-3 col-sm-12">
                <label
                  htmlFor="search"
                  style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                >
                  <b>Search by Name:</b>
                </label>
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12">
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Search by name"
                    type="text"
                    style={{ width: "auto" }}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        props.nameSearch(e.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </div>

              <div className="col-md-2 col-lg-2 col-sm-12 searchButton">
                <Button
                  color="default"
                  onClick={props.nameSearch.bind(null, name)}
                  size="l"
                >
                  Search
                </Button>
              </div>
            </Row>
            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />

            <Row>
              <div className="col-md-3 col-lg-3 col-sm-12">
                <label
                  htmlFor="search"
                  style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                >
                  <b>Search by Phone:</b>
                </label>
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12">
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Search by phone"
                    type="text"
                    style={{ width: "auto" }}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phone}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        props.phoneSearch(e.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </div>

              <div className="col-md-2 col-lg-2 col-sm-12 searchButton">
                <Button
                  color="default"
                  onClick={props.phoneSearch.bind(null, phone)}
                  size="l"
                >
                  Search
                </Button>
              </div>
            </Row>
            <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
            {/* <Row>
              <div className="col-md-3 col-lg-3 col-sm-12">
                <label
                  htmlFor="search"
                  style={{ color: "white", marginLeft: 10, marginTop: 5 }}
                >
                  <b>Search by Invoice No:</b>
                </label>
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12">
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder="Search by Invoice No"
                    type="text"
                    style={{ width: "auto" }}
                    onChange={(e) => setInvoiceNo(e.target.value)}
                    value={invoiceNo}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        props.invoiceNoSearch(e.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </div>

              <div className="col-md-2 col-lg-2 col-sm-12" style={{ marginLeft: "13px" }}>
                <Button
                  color="default"
                  onClick={props.invoiceNoSearch.bind(null, invoiceNo)}
                  size="l"
                >
                  Search
                </Button>
              </div>
            </Row> */}
            {/* <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} /> */}

            <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="fromDate"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white", marginRight: 20 }}
                  >
                    <b>From:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="From"
                    style={{ width: "auto" }}
                    id="fromDate"
                    type="date"
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                  />
                </div>
              </div>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="toDate"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white", marginRight: 40 }}
                  >
                    <b>To: </b>
                  </label>
                </div>

                <div className="col-auto" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="To"
                    style={{ width: "auto" }}
                    id="toDate"
                    type="date"
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        props.filterSearch(fromDate, e.target.value);
                      }
                    }}
                  />
                </div>

                <div
                  className="col-auto"
                  style={{ marginLeft: 15, marginTop: 10 }}
                >
                  <Button
                    color="default"
                    onClick={props.filterSearch.bind(null, fromDate, toDate)}
                    size="l"
                  >
                    Search by Date Range
                  </Button>
                </div>
              </div>
            </Row>

            {/* <Row>
              <div className="row gy-2 gx-3 align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="forDate"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white", marginRight: 22 }}
                  >
                    <b>Date:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <Input
                    placeholder="Date"
                    style={{ width: "auto" }}
                    id="forDate"
                    type="date"
                    onChange={(e) => setForDate(e.target.value)}
                    value={forDate}
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-auto" style={{ marginTop: 10 }}>
                  <label
                    htmlFor="forCenter"
                    className="col-sm-2 col-form-label"
                    style={{ color: "white" }}
                  >
                    <b>Center:</b>
                  </label>
                </div>
                <div className="col-auto" style={{ marginTop: 10, width: 235 }}>
                  <Select
                    id="forCenter"
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="centerList"
                    options={props.centerList}
                    onChange={(e) => {
                      setForCenter(e.value);
                    }
                    }
                  />
                </div>
              </div>

              <div className="align-items-center">
                <div
                  className="col-auto"
                  style={{ marginLeft: 15, marginTop: 10 }}
                >
                  <Button
                    color="default"
                    onClick={props.filterSearchByDateAndCenter.bind(null, forDate, forCenter)}
                    size="l"
                  >
                    Search by Date & Center
                  </Button>
                </div>
              </div>

            </Row> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
