import React, { useState } from "react";
import { useTable } from "react-table";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber } from 'libphonenumber-js';
import Select from "react-select";
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  FormGroup,
  Dropdown,
  Table,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Label,
  Input,
  Col
} from "reactstrap";
// core components
import Header from "../components/Headers/BookingHeader.js";
import Auth from "../helpers/Auth";
import { arrayExpression } from "@babel/types";
import CanScope from "./../helpers/ScopePermissions";
import { useEffect } from "react";
import axios from "axios";
import { from } from "form-data";
import { VACCINATION_CERTIFICATE_ISSUER } from "../helpers/consts";
import PrintComponent from './PrintComponent';
var moment = require("moment");
var momentT = require("moment-timezone");
const fetch = require("node-fetch");
Modal.setAppElement("#root");

let bookingsList = [];

function ReactTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <div
      style={{
        display: "block",
        maxWidth: "100%",
        overflowX: "scroll",
        overflowY: "hidden",
        borderBottom: "1px solid grey",
      }}
      className="rotate-table"
      id="record-table"
    >
      <table
        className="align-items-center table-flush table responsive rotate-table"
        {...getTableProps()}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                column.hideHeader === false ? null : (
                  <th scope="col" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export const signout = () => {
  Auth.signout();
  window.location.reload();
};
const TOTAL_NUMBER_OF_RECORD_PER_PAGE = 500;
var pageNo = 1;
var bookingFilteredList = [];

const Tables = (props) => {
  var isPaginationApplied = false;
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterKeywords, setFilterKeywords] = useState({});
  const [products, setProducts] = useState();
  // const [scheduleList, setScheduleList] = useState();
  const [centerList, setCenters] = useState();
  const [selectedCenter, setSelectedCenters] = useState();
  // For Issue Modal
  const [issueModal, setIssueModal] = useState(false);
  const [arrivedModal, setArrivedModal] = useState(false);
  const [virusName, setVirusName] = useState("");
  const [VaccineName, setVaccineName] = useState("");
  const [VaccineLotsNo, setVaccineLotsNo] = useState("");
  const [IssueDate, setIssueDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [NextissueDate, setNextIssueDate] = useState(
    // moment().add(1, 'days').format("YYYY-MM-DD")
  );
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [futureDate, setFutureDate] = useState(moment().add(1, 'M').format("YYYY-MM-DDTHH:mm"));
  const [issueDateISO, setIssueDateIso] = useState(
    moment(IssueDate).toISOString(true)
  );
  const [adminCenterName, setAdminCenterName] = useState("")
  const [clickedRow, setClickedRow] = useState({});
  const [issueLoading, setIssueLoading] = useState(false);
  // const [schedule, setSchedule] = useState("")
  //todo: Arrived button spinner state
  const [isArrivedDataLoading, setIsArrivedDataLoading] = useState(false)
  const [spinnerCheck, setSpinnerCheck] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)



  //todo: Edit button spinner state
  const [isEditDataLoading, setIsEditDataLoading] = useState(false)

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOKIsOpen, setModalOKIsOpen] = useState(false);
  const [modalNotOKIsOpen, setModalNotOKIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [genderDrop, setGenderDrop] = useState(false);
  const [gender, setGender] = useState("Male");
  const [dob, setDob] = useState("");
  const [docId, setDocId] = useState("");
  const [mccocId, setMccocId] = useState("");
  const [dose, setDose] = useState("");
  const [vaccStatus, setVaccStatus] = useState("Not Vaccinated");
  const [vaccLocDrop, setVaccLocDrop] = useState(false);
  const [fatherName, setFatherName] = useState("");
  const [vaccloc, setVaccLoc] = useState("");
  const [vacclocId, setVaccLocId] = useState("");
  const [nationality, setNationality] = useState("");
  const [adminCenter, setAdminCenter] = useState("");

  const [selectedtimeSlot, setSelectedTimeSlot] = useState("");
  const [bookedDate, setBookedDate] = useState("");
  const [editIndex, setEditIndex] = useState("");
  const [arrivedIndex, setArrivedIndex] = useState("");
  const [timeSlotDrop, setTimeSlotDrop] = useState(false);
  const [toggleMarkVoid, setToggleMarkVoid] = useState(false);
  const [markVoidId, setMarkVoidId] = useState(null);
  const [atcCode, setAtcCode] = useState("");
  const [marketingHolder, setMarketingHolder] = useState("");
  const [paymentStatusDrop, setPaymentStatusDrop] = useState(false);
  const [paymentstatus, setPaymentStatus] = useState("Unpaid");
  const [registrationStatusDrop, setRegistrationStatusDrop] = useState(false);
  const [registrationstatus, setRegistrationStatus] = useState("Arrived");
  const [printValue, setPrintValue] = useState(false);
  const [printData, setPrintData] = useState();
  const [doseList, setDoseList] = useState([]);
  const [preSelectedDose, setPreSelectedDose] = useState();
  const [nextAppointmentFlag, setNextAppointmentFlag] = useState(true);

  let doseModifiedArray = []
  const timeSlotsArray = [
    "12:00AM - 1:00AM",
    "1:00AM - 2:00AM",
    "2:00AM - 3:00AM",
    "3:00AM - 4:00AM",
    "4:00AM - 5:00AM",
    "5:00AM - 6:00AM",
    "6:00AM - 7:00AM",
    "7:00AM - 8:00AM",
    "8:00AM - 9:00AM",
    "9:00AM - 10:00AM",
    "10:00AM - 11:00AM",
    "11:00AM - 12:00PM",
    "12:00PM - 1:00PM",
    "1:00PM - 2:00PM",
    "2:00PM - 3:00PM",
    "3:00PM - 4:00PM",
    "4:00PM - 5:00PM",
    "5:00PM - 6:00PM",
    "6:00PM - 7:00PM",
    "7:00PM - 8:00PM",
    "8:00PM - 9:00PM",
    "9:00PM - 10:00PM",
    "10:00PM - 11:00PM",
    "11:00PM - 12:00AM",
  ];

  const [VaccStatusDropDownOpen, setVaccStatusDropDownOpen] = useState(false);
  const toggleVaccStatusDropDown = () =>
    setVaccStatusDropDownOpen((prevState) => !prevState);
  const handleVaccStatusDropDownSelect = (e) => {
    setVaccStatus(e);
  };

  React.useEffect(() => {
    getProducts();
    // getSchedule();
    getCenters();
    let firstLoad = localStorage.getItem("firstLoad");
    if (firstLoad === "true") {
      let fromDate = moment().format("YYYY-MM-DD");
      let data = loadInitalBookingList(fromDate, fromDate);
      if (data !== undefined) {
        data.then((d) => {
          localStorage.setItem("table", JSON.stringify(d));
          bookingsList = d;
          setTableData(bookingsList);
        });
      }
      localStorage.setItem("firstLoad", false);
    } else {
      let t = localStorage.getItem("table");
      bookingsList = JSON.parse(t);
      setTableData(bookingsList);
    }
  }, []);
  useEffect(() => {
    const conent = document.querySelector("#record-table");
    conent.scrollLeft = conent.scrollWidth - conent.clientWidth;
  }, [tableData]);

  /**
 * Get products from DB
 */
  const getProducts = async () => {
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      var API_PATH =
        process.env.REACT_APP_API_URL + `/api/products`;
      const response = await axios({
        url: API_PATH,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.products) {
        setProducts(response.data.products);
      }
    }
  }

  /**
* Get schedule list from DB
*/
  // const getSchedule = async () => {
  //   let token = Auth.getToken();
  //   if (token === undefined) {
  //     signout();
  //   } else {
  //     var API_PATH =
  //       process.env.REACT_APP_API_URL + `/api/schedule`;
  //     const response = await axios({
  //       url: API_PATH,
  //       method: 'GET',
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //     });
  //     // if (response.data.scheduleArr) {
  //     //   console.log('...', response.data.scheduleArr)
  //     //   setScheduleList(response.data.scheduleArr);
  //     // }
  //   }
  // }

  /**
 * Get centers from DB
 */
  const getCenters = async () => {
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      var API_PATH =
        process.env.REACT_APP_API_URL + `/api/centers`;
      const response = await axios({
        url: API_PATH,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.centerList) {
        setCenters(response.data.centerList);
      }
    }
  }

  const registerBooking = () => {
    setSubmitButtonDisabled(true)
    setSpinnerCheck(true)
    // let rowIndex = clickedRow.rowIndex;
    // let cell = clickedRow.cell;
    // let booking = bookingsList[rowIndex];
    let booking = bookingsList[arrivedIndex]

    //Validation
    let dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (docId == null || docId === undefined || docId === "") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Document ID Is Required.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (dob == null || dob === undefined || dob === "" || !dobRegex.test(dob)) {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Date of birth Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (dose == null || dose === undefined || dose === "") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Dose Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (phone == null || phone === undefined || phone === "" || phone === "+") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Phone Number Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (adminCenter == null || adminCenter === undefined || adminCenter === "" || adminCenter === "Select Center") {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Admin center is required.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    try {
      const parsedPhoneNumber = parsePhoneNumber(phone);
      if (!parsedPhoneNumber.isValid()) {
        setSpinnerCheck(false)
        setSubmitButtonDisabled(false)
        return toast.error(`Phone Number Is Incorrect`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (e) {
      setSpinnerCheck(false)
      setSubmitButtonDisabled(false)
      return toast.error(`Phone Number Incorrect ${e.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    try {
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {

        let data = {
          phone,
          name,
          dob,
          gender,
          nationality,
          docId,
          dose,
          adminCenter
        }

        setIssueLoading(true);

        var API_PATH = process.env.REACT_APP_API_URL + "/api/bookingregister";
        fetch(API_PATH, {
          method: "POST",
          body: JSON.stringify({ bookingId: booking.bookingId, data }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTableData([]);
              booking.registrationstatus = "Arrived";
              booking.phone = phone;
              booking.documentId = docId;
              booking.fullname = name;
              booking.dob = dob;
              booking.gender = gender;
              booking.dose = dose;
              booking.centerName = adminCenterName ? adminCenterName : booking.centerName;
              booking.admin_center = adminCenterName ? adminCenterName : booking.centerName;
              bookingsList[arrivedIndex] = booking;
              setTableData(bookingsList);
              // todo: set state to empty after successfull submission
              setPhone("")
              setName("")
              setDob("")
              setGender("")
              setNationality("")

              setDocId("")
              setAdminCenter("")
              setIsArrivedDataLoading(false)



              setArrivedModal((prev) => !prev);
              setIssueLoading(false);
              setModalOKIsOpen(true);
              setSubmitButtonDisabled(false)
              setSpinnerCheck(false)


            } else {
              setSubmitButtonDisabled(false)
              setSpinnerCheck(false)
              alert("Unable register! " + json.message);
            }
            setDose("")
            setPreSelectedDose("")
            setDoseList()
            doseModifiedArray = []
          });
      }
    } catch (error) {
      console.log(error.message);
      // setIsLoading(false);
    }
  };

  const openIssueModal = (rowIndex, cell) => {
    getDoses(cell.row.original.productID)
    if (cell.row.original.dose) {
      setPreSelectedDose({ label: cell.row.original.dose, value: cell.row.original.dose })
      setDose(cell.row.original.dose)
    }
    setVaccineName({ label: cell.row.original.vaccine, value: cell.row.original.vaccine })
    setSelectedCenters({ label: cell.row.original.centerName, value: cell.row.original.centerName })
    getProduct(cell.row.original.productID)
    setClickedRow({ rowIndex, cell });
    setIssueModal((prev) => !prev);
    setSubmitButtonDisabled(false);

    let booking = bookingsList[rowIndex];
    //setDose(booking.dose);
  };

  const getProduct = async (value) => {
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      var API_PATH =
        process.env.REACT_APP_API_URL + `/api/getProductByID`;
      const response = await axios({
        url: API_PATH,
        params: { prodID: value },
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.prodObj) {
        setVirusName(response.data.prodObj.virusName)
        setAtcCode(response.data.prodObj.atcCode)
        setMarketingHolder(response.data.prodObj.marketingHolder)
      }
    }
  }

  const getDoses = async (value) => {
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      doseModifiedArray = []
      setDose("")
      setPreSelectedDose("")
      setDoseList()
      var API_PATH =
        process.env.REACT_APP_API_URL + `/api/getProductByID`;
      const response = await axios({
        url: API_PATH,
        params: { prodID: value },
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.prodObj && response.data.prodObj.dose) {
        let doseArr = response.data.prodObj.dose;
        for (let i = 0; i < doseArr.length; i++) {
          doseModifiedArray.push({
            value: doseArr[i].toString(),
            label: doseArr[i].toString()
          })
        }
      }
    }
    if (doseModifiedArray && doseModifiedArray.length) {
      setDoseList(doseModifiedArray)
    }
  }

  const ArrivedModelOpen = async (rowIndex, cell) => {
    console.log(cell)
    getDoses(cell.row.original.productID)
    setSelectedCenters({ label: cell.row.original.centerName, value: cell.row.original.centerName })
    if (cell.row.original.dose) {
      setPreSelectedDose({ label: cell.row.original.dose, value: cell.row.original.dose })
    }
    let booking = bookingsList[rowIndex]
    setClickedRow({ rowIndex, cell });
    let token = Auth.getToken();
    setArrivedModal((prev) => !prev);
    // todo: removing api call

    setPhone(booking.phone)
    setName(booking.fullname)
    setDob(booking.dob)
    setGender(booking.gender)
    setNationality(booking.nationality)

    setDocId(booking.documentId)
    //setDose(cell.row.original.dose?cell.row.original.dose:booking.dose)
    setAdminCenter(booking.centerName)
    setArrivedIndex(rowIndex);
    setIsArrivedDataLoading(true)
    setSubmitButtonDisabled(false)

  }

  const markVaccinated = async () => {
    console.log("IssueDate", moment.utc(IssueDate).format('YYYY-MM-DD'));

    if (!issueDateISO) {
      return toast.error(`Please Select Issue Date`);
    }

    // if (!schedule) {
    //   return toast.error(`Please input schedule`);
    // }
    if (!dose) {
      return toast.error(`Please input dose`);
    }
    if (!VaccineLotsNo) {
      return toast.error(`Please Select batch`);
    }

    if (!virusName) {
      return toast.error(`Please wait for information to load!`);
    }

    setSubmitButtonDisabled(true)
    setSpinnerCheck(true)
    setIssueLoading(true);
    let rowIndex = clickedRow.rowIndex;
    let cell = clickedRow.cell;
    let booking = bookingsList[rowIndex];

    try {

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {

        let documentId = booking.documentId ?? "";
        let documentType = documentId.toString().includes("/")
          ? "National Registration Card (NRC)"
          : "Passport";

        let systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();

        var vaccinePass = {
          "Type": "Vaccination Record (" + VaccineName.value.split('=')[0] + ")",
          "Full Name": booking.fullname,
          "Birth Date": booking.dob,
          "Document Id": documentId,
          "Disease": virusName,
          "Atc Code": atcCode,
          "Vaccine Medicinal Product": VaccineName.value.split('=')[0],
          "Marketing Authorisation Holder": marketingHolder,
          "Dose": dose,
          "Batch": VaccineLotsNo,
          // "Date Of Vaccination": moment.utc(momentT.tz(IssueDate, systemTimeZone).format("YYYY-MM-DDTHH:mm")),
          // "Date Of Vaccination": moment.utc(IssueDate).tz(systemTimeZone).utcOffset(-moment.tz(systemTimeZone).utcOffset()).format('YYYY-MM-DD[T]HH:mm:ss').toString(),
          "Date Of Vaccination": moment.utc(IssueDate).format('YYYY-MM-DD'),
          "Administering Centre": selectedCenter.value,
          // "Schedule": schedule,
          "Next Vaccination Date": nextAppointmentFlag ? "N/A" : moment.utc(NextissueDate).format('YYYY-MM-DD'),
          "Country Of Vaccination": "Myanmar",
          // "Next Vaccination Date": nextAppointmentFlag ? "N/A" : moment.utc(NextissueDate).tz(systemTimeZone).utcOffset(-moment.tz(systemTimeZone).utcOffset()).format('YYYY-MM-DD[T]HH:mm:ss').toString(),
          // "Next Vaccination Date": moment.utc(momentT.tz(NextissueDate, systemTimeZone).format("YYYY-MM-DDTHH:mm")),
          "Issuer": VACCINATION_CERTIFICATE_ISSUER

        }

        var API_PATH = process.env.REACT_APP_API_URL + "/api/markvaccinated";

        fetch(API_PATH, {
          method: "POST",
          body: JSON.stringify({
            bookingId: booking.bookingId,
            credential: vaccinePass,
            metadata: { "ATC Code": atcCode, "Product Id": VaccineName.value.split('=')[1] }
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {

              setTableData([]);
              booking.vaccinationstatus = "Vaccinated";
              bookingsList[rowIndex] = booking;
              setTableData(bookingsList);
              setIssueModal(false);
              setIssueLoading(false);
              setModalOKIsOpen(true);
              setSpinnerCheck(false);
              setSubmitButtonDisabled(false);
            } else {
              setSpinnerCheck(false);
              setSubmitButtonDisabled(false);
              setIssueLoading(false);
              setIssueModal(false);
              setModalNotOKIsOpen(true);
            }
            setDose("")
            setPreSelectedDose("")
            setDoseList()
            doseModifiedArray = []
          });
      }
    } catch (error) {
      setSpinnerCheck(false);
      setSubmitButtonDisabled(false);
      setIssueModal(false);
      setIssueLoading(false);
      setModalNotOKIsOpen(true);
      console.log(error.message);
    }
  };

  const markStatusVoid = () => {
    let booking = tableData[markVoidId];
    try {
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        var API_PATH =
          process.env.REACT_APP_API_URL + `/api/markVoid/${booking.bookingId}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              localStorage.setItem("firstLoad", true);

              if (tableData.length === 1) {
                setTableData([]);
                setMarkVoidId(null);
              } else {
                setTableData([]);
                tableData.splice(markVoidId, 1);
                setMarkVoidId(null);

                setTableData(tableData);
              }
              setToggleMarkVoid(false);
              setModalOKIsOpen(true);
              //update stockAmount in product table
              var PRODUCT_API_PATH =
                process.env.REACT_APP_API_URL + "/api/update-products";
              let vaccineId = json.bookings.productID
              fetch(PRODUCT_API_PATH, {
                method: "POST",
                body: JSON.stringify({ vaccineId, deductStock: false, addStock: true }),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              }).then((res) => res.json)
                .then((json) => {
                  console.log('found the document', json)
                })
            } else {
              alert("Unable to Set Void! " + json.message);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const editRecord = () => {
    let booking = bookingsList[editIndex];
    let dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    if (docId == null || docId === undefined || docId === "") {
      return toast.error(`Document ID Is Required.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (dob == null || dob === undefined || dob === "" || !dobRegex.test(dob)) {
      return toast.error(`Date of birth Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (dose == null || dose === undefined || dose === "") {
      return toast.error(`Dose Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (phone == null || phone === undefined || phone === "" || phone === "+") {
      return toast.error(`Phone Number Is Required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    try {
      const parsedPhoneNumber = parsePhoneNumber(phone);
      if (!parsedPhoneNumber.isValid()) {
        return toast.error(`Phone Number Is Incorrect`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (e) {
      return toast.error(`Phone Number Incorrect ${e.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }


    try {
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        // Getting centerId from centerName
        // let centerIndex = centers.findIndex((e) => e.centerName == vaccloc);
        // let centerId = centers[centerIndex].centerId
        let centerId = vacclocId;

        // todo: changes vaccinationlocation value and adding center Attribute
        var API_PATH = process.env.REACT_APP_API_URL + "/api/updatebooking";
        fetch(API_PATH, {
          method: "POST",
          body: JSON.stringify({
            centerId: centerId,
            centerName: adminCenterName ? adminCenterName : booking.centerName,
            bookingId: booking.bookingId,
            name: name,
            dob: dob,
            phone: phone,
            gender: gender,
            documentId: docId,
            dose: dose,
            vaccinationstatus: vaccStatus,
            paymentstatus: paymentstatus,
            fathername: fatherName,
            bookeddate: bookedDate,
            bookedslot: selectedtimeSlot,
            nationality: nationality,
            registrationstatus: registrationstatus
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            // todo: changes vaccinationlocation value and adding center Attribute
            if (json.status === 200) {
              setTableData([]);
              setIsEditDataLoading(false)

              booking.centerId = centerId;
              booking.centerName = adminCenterName ? adminCenterName : booking.centerName;
              booking.fullname = name;
              booking.dob = dob;
              booking.documentId = docId;
              booking.phone = phone;
              booking.gender = gender;
              booking.dose = dose;
              booking.vaccinationstatus = vaccStatus;
              booking.paymentstatus = paymentstatus;
              booking.fathername = fatherName;
              booking.nationality = nationality;
              booking.bookeddate = moment(bookedDate).format("YYYY-MM-DD");
              bookingsList[editIndex] = booking;
              booking.registrationstatus = registrationstatus;

              //  console.log("bookingupdate", booking);
              setTableData(bookingsList);
              closeModal();
              setModalOKIsOpen(true);
              resetUpdatedState();
            } else {
              alert("Unable to update! " + json.message);
            }
          });
      }
    } catch (error) {
      console.log(error.message);
      // setIsLoading(false);
    }
  };
  function YMD(date) {
    var year = date.substring(date.lastIndexOf("-") + 1);
    var month = date.substring(date.indexOf("-") + 1, date.lastIndexOf("-"));
    var day = date.substring(0, date.indexOf("-"));
    return [year, month, day].join("-");
  }
  const markEdit = (rowIndex, cell) => {
    getDoses(cell.row.original.productID)
    if (cell.row.original.dose) {
      setPreSelectedDose({ label: cell.row.original.dose, value: cell.row.original.dose })
    }
    setSelectedCenters({ label: cell.row.original.centerName, value: cell.row.original.centerName })
    let booking = bookingsList[rowIndex];
    setModalEditIsOpen(true);
    setName(booking.fullname);
    setPhone(booking.phone);
    setGender(booking.gender);
    setDob(moment(booking.dob).format("YYYY-MM-DD"));
    setDocId(booking.documentId);
    setPaymentStatus(booking.paymentstatus);
    setFatherName(booking.fathername);
    setVaccLoc(booking.centerName);
    setVaccLocId(booking.centerId)
    setBookedDate(moment(booking.bookeddate).format("YYYY-MM-DD"));
    setSelectedTimeSlot(booking.bookedslot);
    setNationality(booking.nationality)
    //setDose(booking.dose);
    if (
      booking.vaccinationstatus === "" ||
      booking.vaccinationstatus == undefined
    ) {
      setVaccStatus("Not Vaccinated");
    } else {
      setVaccStatus(booking.vaccinationstatus);
    }

    setEditIndex(rowIndex);
    setIsEditDataLoading(true)

  };
  const resetUpdatedState = () => {
    setName("");
    setPhone("");
    setGender("Male");
    setDob("");
    setDocId("");
    setPaymentStatus("Unpaid");
    setVaccStatus("Not Vaccinated");
    setVaccLocDrop(false);
    setFatherName("");
    setVaccLoc("");
    setVaccLocId("");
    setBookedDate("");
    setNationality("")
    setDose("")
    setPreSelectedDose("")
    setDoseList()
    doseModifiedArray = []

  };

  // todo: standarizing the data and time in Registeration App
  var timeFormats = [
    "YYYY-MM-DD[T]HH:mm:ss.SSSZ",
    "MM-DD-YYYY[T]HH:mm:ss.SSSZ",
    "DD-MM-YYYY[T]HH:mm:ss.SSSZ",
    // with forward slash
    "YYYY/MM/DD HH:mm:ss",
    "MM/DD/YYYY HH:mm:ss",
    "DD/MM/YYYY HH:mm:ss",
    // with hyphen
    "YYYY-MM-DD HH:mm:ss",
    "MM-DD-YYYY HH:mm:ss",
    "DD-MM-YYYY HH:mm:ss",
  ];

  var dateFormats = [
    // with forward slash
    "YYYY/MM/DD",
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    // with hyphen
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "DD-MM-YYYY",
  ];

  const is_date_time = (val) => {
    if (!moment(val, timeFormats, true).isValid()) return false

    return true
  }

  const is_date = (val) => {
    if (!moment(val, dateFormats, true).isValid()) return false

    return true
  }

  const get_local_issue_time = (val) => {
    return moment
      .utc(val, timeFormats)
      .local()
      .format('YYYY-MM-DD HH:mm (G[M]T Z)')
      .toString();
  };

  const parse_date_time = (val) => {
    if (is_date(val)) {
      return moment(moment(val, ["DD-MM-YYYY", "YYYY-MM-DD", "DD/MM/YYYY", "YYYY/MM/DD"])).format("YYYY-MM-DD");
    }

    if (is_date_time(val)) {
      return get_local_issue_time(val);
    }

    return val;
  }

  const loadSearchBookingListByPhone = (phone) => {
    try {

      setFilterKeywords({ phone });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);
        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?phone=${phone}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  appointmentId: booking.appointmentId,
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  vaccine: booking.vaccine,
                  productID: booking.productID,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                  virusName: booking.virusName,
                  marketingHolder: booking.marketingHolder,
                  atcCode: booking.atcCode
                };
                localStorage.setItem('diseaseName', data.virusName);
                localStorage.setItem('marketingHolderName', data.marketingHolder);
                localStorage.setItem('atcCodeInfo', data.atcCode);

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }
                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadSearchBookingListByFullname = (fullname) => {
    try {

      setFilterKeywords({ fullname });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);
        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?fullname=${fullname}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  appointmentId: booking.appointmentId,
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  vaccine: booking.vaccine,
                  productID: booking.productID,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                  virusName: booking.virusName,
                  marketingHolder: booking.marketingHolder,
                  atcCode: booking.atcCode
                };
                localStorage.setItem('diseaseName', data.virusName);
                localStorage.setItem('marketingHolderName', data.marketingHolder);
                localStorage.setItem('atcCodeInfo', data.atcCode);

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }
                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadSearchBookingListByID = (appointmentId) => {
    try {

      setFilterKeywords({ appointmentId });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);
        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?appointmentId=${appointmentId}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  appointmentId: booking.appointmentId,
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  fathername: booking.fathername,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  vaccine: booking.vaccine,
                  productID: booking.productID,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                  virusName: booking.virusName,
                  marketingHolder: booking.marketingHolder,
                  atcCode: booking.atcCode
                };
                localStorage.setItem('diseaseName', data.virusName);
                localStorage.setItem('marketingHolderName', data.marketingHolder);
                localStorage.setItem('atcCodeInfo', data.atcCode);
                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });
              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadSearchBookingListByInvoiceNo = (invoiceNo) => {
    try {

      setFilterKeywords({ invoiceNo });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        // setTableData([]);

        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?invoiceNo=${invoiceNo}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  appointmentId: booking.appointmentId,
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  fathername: booking.fathername,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  vaccine: booking.vaccine,
                  productID: booking.productID,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  invoiceNo: booking.invoiceNo,
                  virusName: booking.virusName,
                  marketingHolder: booking.marketingHolder,
                  atcCode: booking.atcCode
                };

                localStorage.setItem('diseaseName', data.virusName);
                localStorage.setItem('marketingHolderName', data.marketingHolder);
                localStorage.setItem('atcCodeInfo', data.atcCode);

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });
              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }

    isPaginationApplied = false;

  };

  const loadInitalBookingList = (fromDate, endDate) => {
    try {

      setFilterKeywords({ fromDate, endDate });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);

        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?fromDate=${fromDate}&toDate=${endDate}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        return fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  appointmentId: booking.appointmentId,
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  vaccine: booking.vaccine,
                  productID: booking.productID,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  fathername: booking.fathername,
                  invoiceNo: booking.invoiceNo,
                  virusName: booking.virusName,
                  marketingHolder: booking.marketingHolder,
                  atcCode: booking.atcCode
                };

                localStorage.setItem('diseaseName', data.virusName);
                localStorage.setItem('marketingHolderName', data.marketingHolder);
                localStorage.setItem('atcCodeInfo', data.atcCode);

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
            return bookingsList;
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      return undefined;
    }

    isPaginationApplied = false;

  };

  const loadSearchByDateAndCenter = (forDate, forCenter) => {
    try {

      if (!forCenter) {
        return toast.error(`Please Select A Center`);
      }

      setFilterKeywords({ forDate, forCenter });

      if (!isPaginationApplied) {
        pageNo = 1;
        bookingFilteredList = [];
        setTableData([]);
      }

      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        setIsLoading(true);
        var API_PATH = `${process.env.REACT_APP_API_URL}/api/filter_bookings?forDate=${forDate}&forCenter=${forCenter}&pagination=${TOTAL_NUMBER_OF_RECORD_PER_PAGE}&page=${pageNo}`;
        return fetch(API_PATH, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              setTotalRecords(json.totalRecords);
              let bookingsArr = json.bookings;
              bookingsList = [];
              bookingsArr.forEach(function (booking) {
                let bookedDate = moment(booking.bookeddate).format("YYYY-MM-DD");
                let data = {
                  appointmentId: booking.appointmentId,
                  centerId: booking.centerId,
                  centerName: booking.centerName,
                  bookingId: booking.bookingId,
                  fullname: booking.fullname,
                  company: booking.company,
                  employeeId: booking.employeeId,
                  nationality: booking.nationality,
                  documentId: booking.documentId || booking.passport,
                  gender: booking.gender,
                  phone: booking.phone,
                  paymentstatus: booking.paymentstatus,
                  bookeddate: bookedDate,
                  bookedslot: booking.bookedslot,
                  registrationstatus: booking.registrationstatus,
                  vaccine: booking.vaccine,
                  productID: booking.productID,
                  dose: booking.dose,
                  vaccinationstatus: booking.vaccinationstatus,
                  dob: booking.dob,
                  fathername: booking.fathername,
                  invoiceNo: booking.invoiceNo,
                  virusName: booking.virusName,
                  marketingHolder: booking.marketingHolder,
                  atcCode: booking.atcCode
                };

                localStorage.setItem('diseaseName', data.virusName);
                localStorage.setItem('marketingHolderName', data.marketingHolder);
                localStorage.setItem('atcCodeInfo', data.atcCode);

                for (let key in data) {
                  data[key] = parse_date_time(data[key])
                }

                bookingsList.push(data);
              });

              bookingFilteredList = [...bookingFilteredList, ...bookingsList]
              setTableData(bookingFilteredList);
            } else {
              bookingsList = [];
              setTableData(bookingsList);
            }
            setIsLoading(false);
            return bookingsList;
          });
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      return undefined;
    }

    isPaginationApplied = false;

  };

  const ToggleMarkVoid = (index) => {
    setMarkVoidId(index);
    setToggleMarkVoid(!toggleMarkVoid);
  };
  const TogglePrint = (data) => {
    setPrintValue(true)
    setPrintData(data.row.original)
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        hideHeader: false,
        columns: [
          {
            Header: "Action",
            Cell: ({ cell }) => (
              <Row className="action-button">
                {CanScope("mark_arrived") && (
                  <Row className="ml-0 mr-0">
                    <Button
                      color="default"
                      size="sm"
                      onClick={() => ArrivedModelOpen(cell.row.index, cell)}
                    >
                      Arrived
                    </Button>
                  </Row>
                )}

                {CanScope("edit_details") && (
                  <Row>
                    <Button
                      color="info"
                      size="sm"
                      style={{ marginLeft: 20 }}
                      onClick={() => markEdit(cell.row.index, cell)}
                    >
                      Edit
                    </Button>
                  </Row>
                )}

                {CanScope("mark_vaccinated") && (
                  <Row>
                    <Button
                      color="primary"
                      size="sm"
                      style={{ marginLeft: 35 }}
                      onClick={() => {
                        openIssueModal(cell.row.index, cell);
                      }}
                    >
                      Mark Vaccinated
                    </Button>
                  </Row>
                )}
                {CanScope("mark_void") && (
                  <Row>
                    <Button
                      color="warning"
                      size="sm"
                      style={{ marginLeft: 35 }}
                      onClick={() => ToggleMarkVoid(cell.row.index)}
                    >
                      Mark Void
                    </Button>
                  </Row>
                )}
                {CanScope("print") && (
                  <Row>
                    <Button
                      color="warning"
                      size="sm"
                      style={{ marginLeft: 35 }}
                      onClick={() => TogglePrint(cell)}
                    >
                      Print
                    </Button>
                  </Row>
                )}
              </Row>
            ),
          },
          {
            Header: "Appointment ID",
            accessor: "appointmentId",
          },
          {
            width: 150,
            Header: "Full Name",
            accessor: "fullname",
          },
          {
            Header: "Vaccine Medicinal Product",
            accessor: "vaccine",
          },
          {
            Header: "Dose",
            accessor: "dose",
          },
          {
            Header: "Booked Date",
            accessor: "bookeddate",
          },
          {
            Header: "Booked Timeslot",
            accessor: "bookedslot",
          },
          {
            Header: "Payment Status",
            accessor: "paymentstatus",
          },
          {
            Header: "Registration Status",
            accessor: "registrationstatus",
          },
          {
            Header: "Vaccination Status",
            accessor: "vaccinationstatus",
          },
          {
            Header: "DOB",
            accessor: "dob",
          },
          {
            Header: "Gender",
            accessor: "gender",
          },
          {
            Header: "Nationality",
            accessor: "nationality",
          },
          {
            Header: "Booking ID",
            accessor: "bookingId",
          },
          {
            Header: "Invoice No",
            accessor: "invoiceNo",
          },
          {
            Header: "Phone Number",
            accessor: "phone",
          },
          {
            Header: "Center Name",
            accessor: "centerName",
          },
          // {
          //   Header: "Company",
          //   accessor: "company",
          // },
          // {
          //   Header: "Employee Id",
          //   accessor: "employeeId",
          // },
          // {
          //   Header: "NRC or Passport Number",
          //   accessor: "documentId",
          // },
        ],
      },
    ],
    []
  );

  function closeModal() {
    setIsEditDataLoading(false)
    setModalOKIsOpen(false);
    setModalNotOKIsOpen(false);
    setModalEditIsOpen(false);
    setDose("")
    setPreSelectedDose("")
    setDoseList()
    doseModifiedArray = []

  }

  const HandleArrivedClose = () => {
    setArrivedModal(!arrivedModal);
    setSubmitButtonDisabled(true)

    // todo: set state to empty after close Arrived Button
    setPhone("")
    setName("")
    setDob("")
    setGender("")
    setNationality("")
    setDocId("")
    setDose("")
    setPreSelectedDose("")
    setAdminCenter("")
    setIsArrivedDataLoading(false)
    setSubmitButtonDisabled(false)
    setDoseList()
    doseModifiedArray = []




  }

  const HandleMarkVaccinatedClose = () => {
    setIssueModal(!issueModal);

    // set state to empty after close Arrived Button
    setDose("")
    setPreSelectedDose("")
    setDoseList()
    doseModifiedArray = []
  }

  const loadMore = () => {
    pageNo++;
    getData();
  }

  const getData = () => {

    isPaginationApplied = true;

    filterKeywords.hasOwnProperty("phone") && loadSearchBookingListByPhone(filterKeywords.phone);
    filterKeywords.hasOwnProperty("name") && loadSearchBookingListByFullname(filterKeywords.fullname);
    filterKeywords.hasOwnProperty("appointmentId") && loadSearchBookingListByID(filterKeywords.appointmentId);
    filterKeywords.hasOwnProperty("bookingId") && loadSearchBookingListByInvoiceNo(filterKeywords.bookingId);
    filterKeywords.hasOwnProperty("invoiceNo") && loadSearchBookingListByInvoiceNo(filterKeywords.invoiceNo);
    filterKeywords.hasOwnProperty("fromDate") && loadInitalBookingList(filterKeywords.fromDate, filterKeywords.endDate);
    filterKeywords.hasOwnProperty("forDate") && loadInitalBookingList(filterKeywords.forDate, filterKeywords.forCenter);

  }

  return (
    <>
      <ToastContainer />
      <Modal
        shouldCloseOnOverlayClick={true}
        backdrop="static"
        isOpen={issueModal}
        toggle={() => setIssueModal(!issueModal)}
        size="sm"
        width="100%"
        className={issueModal ? "credmodal issueModal" : arrivedModal ? "credmodal arrivedModal" : modalEditIsOpen ? "credmodal editModal" : "credmodal"}
        overlayClassName="credoverlay"
      >
        {!issueLoading ? (
          <div id="arrived-modal" style={{ width: "100%", overflowX: "hidden", height: "600px", paddingLeft: "25px" }}>
            <Row className="centered-content d-flex justify-content-center">
              <h4>Credential Vaccine Information</h4>
            </Row>
            <br />
            {/* Disease and ATC code */}
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    Disease
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-manufacturer"
                    value={virusName}
                    // onChange={(e) => setVirusName(e.target.value)}
                    disabled
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    ATC code
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-manufacturer"
                    value={atcCode}
                    // onChange={(e) => setVirusName(e.target.value)}
                    disabled
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Vaccine Medicinal Product */}
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-vaccinename"
                  >
                    Vaccine Medicinal Product
                  </label>
                  <div id="input-eventtype">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      name="products"
                      options={products}
                      defaultValue={VaccineName}
                      onChange={(e) => {
                        setVaccineName(e)
                        getProduct(e.value.split('=')[1])
                      }
                      }
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-vaccinename"
                  >
                    Administering center
                  </label>
                  <div id="input-eventtype">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      name="centerList"
                      options={centerList}
                      defaultValue={selectedCenter}
                      onChange={(e) =>
                        setAdminCenterName(e.value)
                      }
                    />
                  </div>
                </FormGroup>
              </Col>

            </Row>
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    Marketing Authorisation Holder
                  </label>
                  <Input
                    className="form-control-alternative"
                    disabled={true}
                    id="input-manufacturer"
                    value={marketingHolder}
                    type="text"
                  />
                </FormGroup>
              </Col>
              {/* <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    Schedule<span className="text-danger">*</span>
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="schedule"
                    options={scheduleList}
                    // defaultValue={preSelectedDose}
                    onChange={(e) => {
                      setSchedule(e.value)
                    }
                    }
                  />
                </FormGroup>
              </Col> */}
              {/* </Row>
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            > */}
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    Dose<span className="text-danger">*</span>
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="doses"
                    options={doseList}
                    defaultValue={preSelectedDose}
                    onChange={(e) => {
                      setDose(e.value)
                    }
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    Batch<span className="text-danger">*</span>
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-manufacturer"
                    type="text"
                    placeholder="Enter batch no."
                    onChange={(e) => setVaccineLotsNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-manufacturer"
                  >
                    Country of vaccination
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-manufacturer"
                    type="text"
                    disabled
                    value="Myanmar"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ textAlignLast: "right", marginRight: "23%", marginTop: "20px" }}>
              <Col>
                <FormGroup>
                  <Input
                    defaultChecked={nextAppointmentFlag}
                    type="checkbox"
                    onClick={(e) => {
                      if (!e.target.checked) {
                        setNextIssueDate(moment().add(1, 'days').format("YYYY-MM-DD"))
                      } else {
                        setNextIssueDate()
                      }

                      setNextAppointmentFlag(e.target.checked)
                    }}
                  />
                  <Label check>
                    No Next Appointment
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row
              className="d-flex justify-content-center"
              style={{ width: "100%", alignSelf: "center" }}
            >
              <Col>
                <FormGroup style={{ width: "100%" }}>
                  <label
                    className="form-control-label"
                    htmlFor="input-issue-date"
                  >
                    Date of vaccination
                  </label>

                  <Input
                    className="form-control-alternative"
                    id="input-issue-date"
                    defaultValue={IssueDate}
                    value={IssueDate}
                    min={currentDate}
                    max={NextissueDate}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setIssueDate(e.target.value);
                      setIssueDateIso(moment(e.target.value).toISOString(true));
                    }}
                    type="date"
                  />
                </FormGroup>
              </Col>
              {
                nextAppointmentFlag ?
                  <Col>
                    <FormGroup style={{ width: "100%" }}>
                      <label
                        className="form-control-label"
                        htmlFor="input-manufacturer"
                      >
                        Next vaccination date
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-manufacturer"
                        type="text"
                        disabled
                        value="N/A"
                      />
                    </FormGroup>
                  </Col> :
                  <Col>
                    <FormGroup style={{ width: "100%" }}>
                      <label
                        className="form-control-label"
                        htmlFor="input-issue-date"
                      >
                        Next vaccination date
                      </label>

                      <Input
                        className="form-control-alternative"
                        id="input-issue-date"
                        value={NextissueDate}
                        min={IssueDate}
                        onChange={(e) => {
                          setNextIssueDate(e.target.value);
                        }}
                        type="date"
                      />
                    </FormGroup>
                  </Col>
              }
            </Row>
            <ModalFooter>
              <Button
                color="primary"
                disabled={submitButtonDisabled}
                onClick={() => {
                  markVaccinated();
                }}
              >
                {!spinnerCheck ? ("Submit") : <div>Submit <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}

              </Button>{" "}
              <Button
                color="secondary"
                disabled={submitButtonDisabled}
                onClick={() => HandleMarkVaccinatedClose()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div >
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              id="spinner"
              className="spinner-border centered-content"
              role="status"
            />
            <h5 style={{ marginTop: 10 }}>Marking as vaccinated...</h5>
          </div>
        )}
      </Modal>

      {/* ----------------------------------- */}
      <Modal
        shouldCloseOnOverlayClick={true}
        backdrop="static"
        isOpen={arrivedModal}
        toggle={() => setArrivedModal(!arrivedModal)}
        size="sm"
        width="100%"
        className={issueModal ? "credmodal issueModal" : arrivedModal ? "credmodal arrivedModal" : modalEditIsOpen ? "credmodal editModal" : "credmodal"}
        overlayClassName="credoverlay"
      >

        <div id="arrived-modal" style={{ width: "100%", overflowX: "hidden", height: "400px", paddingLeft: "25px" }}>
          <Row className="centered-content d-flex justify-content-center">
            <h4>Credential User Information</h4>
          </Row>
          <br />
          {isArrivedDataLoading ? (
            <>
              <Row>
                <Col xl="4" md="4" sm="12" className="pt-2">
                  <FormGroup>
                    <Label
                      className="form-control-label"
                      for="exampleEmail">
                      Phone Number
                      <span className="text-danger">*</span>
                    </Label>
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country={"mm"}
                      value={phone}
                      onChange={(e) => {
                        setPhone("+" + e);
                      }}
                    />

                  </FormGroup>
                </Col>
                <Col Col xl="4" md="4" sm="12" className="pt-2">
                  <FormGroup style={{ width: "100%" }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-docid"
                    >
                      Document Id
                    </label>
                    <Input
                      className="form-control-alternative"
                      // disabled={true}
                      id="input-docid"
                      value={docId}
                      onChange={(e) => setDocId(e.target.value)}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col xl="4" md="4" sm="12" className="pt-2 pr-4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-fullname"
                    >
                      Full Name
                    </label>
                    <Input
                      className="form-control-alternative"
                      // disabled={true}
                      id="input-fullname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col Col xl="4" md="4" sm="12" className="pt-2">
                  <FormGroup style={{ width: "100%" }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-dob"
                    >
                      Birth Date
                    </label>

                    <Input
                      style={{ height: "40px" }}
                      type="date"
                      id="input-dob"
                      className="form-control form-control-sm"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col Col xl="4" md="4" sm="12" className="pt-2">
                  <FormGroup style={{ width: "100%" }}>
                    <label className="form-control-label" htmlFor="input-gender">
                      Gender
                    </label>
                    <Dropdown
                      isOpen={genderDrop}
                      toggle={() => setGenderDrop(!genderDrop)}
                    >
                      <DropdownToggle color="white" caret className="dropdown">
                        {gender}
                      </DropdownToggle>
                      <DropdownMenu className="menu-dropdown">
                        <DropdownItem onClick={() => setGender("Male")}>
                          Male
                        </DropdownItem>
                        <DropdownItem onClick={() => setGender("Female")}>
                          Female
                        </DropdownItem>
                        <DropdownItem onClick={() => setGender("Other")}>
                          Other
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col Col xl="4" md="4" sm="12" className="pt-2 pr-4">
                  <FormGroup style={{ width: "100%" }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-nationality"
                    >
                      Nationality
                    </label>
                    <Input
                      className="form-control-alternative"
                      // disabled={true}
                      id="input-nationality"
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col Col xl="4" md="4" sm="12" className="pt-2">
                  <FormGroup style={{ width: "100%" }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-dose"
                    >
                      Dose
                    </label>
                    <Select
                      menuPlacement="top"
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      name="doses"
                      options={doseList}
                      defaultValue={preSelectedDose}
                      onChange={(e) => {
                        setDose(e.value)
                      }
                      }
                    />
                  </FormGroup>
                </Col>
                <Col Col xl="8" md="8" sm="12" className="pt-2 pr-4">
                  <FormGroup style={{ width: "100%" }}>
                    <label
                      className="form-control-label"
                      htmlFor="input-vaccinename"
                    >
                      Administering center
                    </label>
                    <div id="input-eventtype">
                      <Select
                        menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="centerList"
                        options={centerList}
                        defaultValue={selectedCenter}
                        onChange={(e) => {
                          setAdminCenter(e.value);
                          setAdminCenterName(e.value)
                        }
                        }
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>

            </>
          ) : (
            <>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <br />
              <div class="d-flex justify-content-center">
                <h3>Loading...</h3>
              </div>
            </>
          )}
        </div>
        <ModalFooter>
          <Button
            color="primary"
            disabled={submitButtonDisabled}
            onClick={() => {
              registerBooking()
            }}
          >
            {!spinnerCheck ? ("Submit") : <div>Submit <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}
          </Button>{" "}
          <Button
            color="secondary"
            disabled={submitButtonDisabled}
            onClick={() => {
              HandleArrivedClose()
            }}
          >
            Cancel
          </Button>

        </ModalFooter>

      </Modal>


      {/* ------------------------------------------- */}

      <Header
        filterSearch={loadInitalBookingList}
        filterSearchByDateAndCenter={loadSearchByDateAndCenter}
        appointmentIdSearch={loadSearchBookingListByID}
        nameSearch={loadSearchBookingListByFullname}
        phoneSearch={loadSearchBookingListByPhone}
        invoiceNoSearch={loadSearchBookingListByInvoiceNo}
        centerList={centerList}
      />
      <Modal
        isOpen={toggleMarkVoid}
        toggle={() => setToggleMarkVoid(!toggleMarkVoid)}
        size="sm"
        className="qrmodal width-70"
        overlayClassName="qroverlay"
      >
        <ModalHeader toggle={() => setToggleMarkVoid(!toggleMarkVoid)}>
          Alert
        </ModalHeader>
        <ModalBody>Are you sure to mark this record as void?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => markStatusVoid()}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={() => setToggleMarkVoid(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Modal
          shouldCloseOnOverlayClick={false}
          backdrop="static"
          isOpen={modalNotOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Certificate Issuance Failed</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>Unable to Issue Certificate</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={require("../assets/img/theme/cross.png").default}
            />
          </div>
          <br />
          <Button
            style={{
              width: "100%",
              backgroundColor: "#c82606",
              color: "#ffffff",
            }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          backdrop="static"
          isOpen={modalOKIsOpen}
          contentLabel="My dialog"
          className="qrmodal"
          overlayClassName="qroverlay"
        >
          <div className="centered-content d-flex justify-content-center">
            <h4>Operation Successful</h4>
          </div>
          <br />
          <div className="centered-content d-flex justify-content-center">
            <p>Operation is successfully completed!</p>
          </div>
          <div className="centered-content d-flex justify-content-center">
            <img
              alt="..."
              height="96px"
              src={require("../assets/img/theme/check.png").default}
            />
          </div>
          <br />
          <Button
            color="success"
            style={{ width: "100%" }}
            onClick={closeModal}
          >
            Continue
          </Button>
        </Modal>

        {/* EDIT DIALOG */}
        <Modal
          shouldCloseOnOverlayClick={true}
          backdrop="static"
          isOpen={modalEditIsOpen}
          contentLabel="My dialog"
          size="sm"
          width="100%"
          className={issueModal ? "credmodal issueModal" : arrivedModal ? "credmodal arrivedModal" : modalEditIsOpen ? "credmodal editModal" : "credmodal"}
          overlayClassName="credoverlay"
        >
          <div id="arrived-modal" style={{ width: "100%", overflowX: "hidden", height: "600px", paddingLeft: "25px" }}>

            <div className="centered-content d-flex justify-content-center">
              <h4>Update Record</h4>
            </div>
            <br />
            {isEditDataLoading ? (
              <>

                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {" "}
                    <div className="form-outline">
                      <label className="form-label" htmlFor="f1">
                        Name
                      </label>
                      <Input
                        type="text"
                        id="f1"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {" "}
                    <div className="form-outline">
                      <label className="form-label" htmlFor="f1">
                        Father Name
                      </label>
                      <Input
                        type="text"
                        id="f1"
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="form-outline">
                      <Label for="exampleEmail">
                        Phone Number (ဖုန်းနံပါတ်)
                        <span className="text-danger">*</span>
                      </Label>
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country={"mm"}
                        value={phone}
                        onChange={(e) => {
                          setPhone("+" + e);
                        }}
                      />

                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="form-outline" style={{ marginTop: 10 }}>
                      <label className="form-label" htmlFor="f2">
                        Gender
                      </label>
                      <Dropdown
                        isOpen={genderDrop}
                        toggle={() => setGenderDrop(!genderDrop)}
                      >
                        <DropdownToggle color="white" caret className="dropdown">
                          {gender}
                        </DropdownToggle>
                        <DropdownMenu className="menu-dropdown">
                          <DropdownItem onClick={() => setGender("Male")}>
                            Male
                          </DropdownItem>
                          <DropdownItem onClick={() => setGender("Female")}>
                            Female
                          </DropdownItem>
                          <DropdownItem onClick={() => setGender("Other")}>
                            Other
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {" "}
                    <div className="form-outline" style={{ marginTop: 10 }}>
                      <label className="form-label" htmlFor="f3">
                        DOB<span className="text-danger">*</span>
                      </label>
                      <Input
                        type="date"
                        id="f3"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <label className="form-label" htmlFor="f4">
                        Document ID<span className="text-danger">*</span>
                      </label>
                      <Input
                        type="text"
                        id="f4"
                        value={docId}
                        required={true}
                        onChange={(e) => setDocId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <label className="form-label" htmlFor="f41">
                        Nationality
                      </label>
                      <Input
                        type="text"
                        id="f41"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {" "}
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <FormGroup>
                        <label className="input-dose" htmlFor="f5">
                          Dose<span className="text-danger">*</span>
                        </label>
                        <div id="f5">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="doses"
                            options={doseList}
                            defaultValue={preSelectedDose}
                            onChange={(e) => {
                              setDose(e.value)
                            }
                            }
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <label className="form-label" htmlFor="ff4">
                        Payment Status
                      </label>
                      <div id="f5">
                        <Dropdown
                          isOpen={paymentStatusDrop}
                          toggle={() =>
                            setPaymentStatusDrop(!paymentStatusDrop)
                          }
                        >
                          <DropdownToggle
                            color="white"
                            caret
                            className="dropdown"
                          >
                            {paymentstatus}
                          </DropdownToggle>
                          <DropdownMenu className="menu-dropdown">
                            <DropdownItem
                              onClick={() => setPaymentStatus("Paid")}
                            >
                              Paid
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => setPaymentStatus("Unpaid")}
                            >
                              Unpaid
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {" "}
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <FormGroup>
                        <label className="input-dose" htmlFor="f5">
                          Center Name
                        </label>
                        <div id="f5">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={true}
                            name="centerList"
                            options={centerList}
                            defaultValue={selectedCenter}
                            onChange={(e) => {
                              setAdminCenterName(e.value)
                              setAdminCenter(e.value);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="form-outline" style={{ marginTop: 10 }}>
                      <label className="form-label" htmlFor="f3">
                        Booked Date
                      </label>
                      <Input
                        type="date"
                        id="f3"
                        value={bookedDate}
                        onChange={(e) => setBookedDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12">
                    {" "}
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <FormGroup>
                        <label className="input-dose" htmlFor="f5">
                          Time Slot
                        </label>
                        <div id="f5">
                          <Dropdown
                            isOpen={timeSlotDrop}
                            toggle={() => setTimeSlotDrop(!timeSlotDrop)}
                          >
                            <DropdownToggle color="white" caret className="dropdown">
                              {selectedtimeSlot}
                            </DropdownToggle>
                            <DropdownMenu
                              className="menu-dropdown"
                              style={{ overflow: "auto", maxHeight: "25vh" }}
                            >
                              {timeSlotsArray.map((slot, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() => setSelectedTimeSlot(slot)}
                                >
                                  {slot}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <FormGroup>
                        <label className="input-vacc" htmlFor="f6">
                          Vaccination Status
                        </label>
                        <div id="f6">
                          <Dropdown
                            isOpen={VaccStatusDropDownOpen}
                            toggle={toggleVaccStatusDropDown}
                          >
                            <DropdownToggle color="white" caret className="dropdown">
                              {vaccStatus}
                            </DropdownToggle>
                            <DropdownMenu className="menu-dropdown">
                              <DropdownItem
                                onClick={() =>
                                  handleVaccStatusDropDownSelect("Vaccinated")
                                }
                              >
                                Vaccinated
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleVaccStatusDropDownSelect("Not Vaccinated")
                                }
                              >
                                Not Vaccinated
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="form-outline mb-4" style={{ marginTop: 10 }}>
                      <FormGroup>
                        <label className="input-dose" htmlFor="f5">
                          Registration Status
                        </label>
                        <div id="f5">
                          <Dropdown
                            isOpen={registrationStatusDrop}
                            toggle={() =>
                              setRegistrationStatusDrop(
                                !registrationStatusDrop
                              )
                            }
                          >
                            <DropdownToggle
                              color="white"
                              caret
                              className="dropdown"
                            >
                              {registrationstatus}
                            </DropdownToggle>
                            <DropdownMenu className="menu-dropdown">
                              <DropdownItem
                                onClick={() =>
                                  setRegistrationStatus("Arrived")
                                }
                              >
                                Arrived
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setRegistrationStatus("Pre-booking")
                                }
                              >
                                Pre-booking
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  setRegistrationStatus("Phone-booking")
                                }
                              >
                                Phone-booking
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <br />
                <div class="d-flex justify-content-center">
                  <h3>Loading...</h3>
                </div>
              </>
            )}

            <div className="btn-group btn-block">
              <Button
                className="btn btn-success mr-2 rounded"
                color="success"
                disabled={!isEditDataLoading}
                style={{ width: "100%", paddingRight: 10 }}
                onClick={editRecord}
              >
                Update
              </Button>
              <Button
                className="btn btn-danger ml-2 rounded"
                color="info"
                style={{ width: "100%", paddingLeft: 10 }}
                onClick={closeModal}
                disabled={!isEditDataLoading}

              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
        {/* EDIT DIALOG */}

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between">
                  <h3 className="mb-0">Booking List</h3>
                  <h3 className="mb-0"> Total Records : {tableData.length}/{totalRecords}</h3>
                </div>
              </CardHeader>
              {!!tableData?.length ? (
                <ReactTable columns={columns} data={tableData} />
              ) : (
                <div
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    borderBottom: "1px solid grey",
                  }}
                  className="rotate-table"
                  id="record-table"
                >
                  <Table className="align-items-center table-flush rotate-table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Appointment ID</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Vaccine Medicinal Product</th>
                        <th scope="col">Dose</th>
                        <th scope="col">Booked Date</th>
                        <th scope="col">Booked Timeslot</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Registration Status</th>
                        <th scope="col">Vaccination Status</th>
                        <th scope="col">DOB</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Nationality</th>
                        <th scope="col">Booking ID</th>
                        <th scope="col">Invoice No</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Center Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          {isLoading ? (
                            <div> Loading... </div>
                          ) : (
                            <div>No data found</div>
                          )}
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {
                tableData.length > 0 && tableData.length < totalRecords && <div className="text-center my-4">
                  <button disabled={isLoading} className="btn btn-primary" onClick={loadMore}> Load More {isLoading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </button>
                </div>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
