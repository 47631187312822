import React, { useRef } from "react";
import { Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";

export default function PrintComponent(props) {
    let componentRef = useRef();
    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <Button>Print this out!</Button>}
                    content={() => componentRef}
                />

                {/* component to be printed */}
                <div style={{ display: "none" }}>
                    <ComponentToPrint
                        data={props.data}
                        ref={(el) => (componentRef = el)} />
                </div>

            </div>
        </>
    );
}