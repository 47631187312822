import React from 'react';
import './ComponentToPrint.css'
const moment = require("moment");
class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const image = localStorage.getItem('image');
        let totalPrice = 0;
        var CurrentDate = moment().format("ddd DD-MM-YYYY HH:mm");
        return (
            <div className="invoice-box">
                <table cellpadding="0" cellspacing="0">
                    <tr className="top">
                        <td colspan="2">
                            <table>
                                <tr>
                                    <td className="title">
                                        <img style={{ maxHeight: '10rem', maxWidth: '100%' }} src={image} alt='...' />
                                    </td>

                                    <td>
                                        Invoice #: {this.props.data[0].invoiceNo}<br />
                                        Created: {CurrentDate}<br />
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr className="information">
                        <td colspan="2">
                            <table>
                                <tr>
                                    <td>
                                        {this.props.data[0].fullName}<br />
                                    </td>

                                    <td>
                                        {this.props.data[0].permanentaddress}<br />
                                        {this.props.data[0].township} <br />
                                        {this.props.data[0].state}<br />
                                        {this.props.data[0].phone}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr className="heading">
                        <td>Item</td>

                        <td>Price</td>
                    </tr>
                    {this.props.data.map((item, i) => {
                        totalPrice += parseInt(item.productPrice);
                        return <tr className="item" key={i} value={item}>
                            <td>{item.vaccine}</td>
                            <td>{item.productPrice}</td>
                        </tr>;
                    })
                    }
                    <tr className="total">
                        <td></td>

                        <td>Total: ${totalPrice}</td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default ComponentToPrint