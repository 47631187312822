var permissions = {
  admin: {
    mark_arrived: true,
    edit_details: true,
    create_booking: true,
    mark_void: true,
    mark_vaccinated: true,
    print: false,
  },
  registration: {
    mark_arrived: true,
    edit_details: true,
    create_booking: true,
    mark_void: true,
    mark_vaccinated: false,
    print: false,
  },
  vaccination: {
    mark_arrived: false,
    edit_details: true,
    create_booking: false,
    mark_void: false,
    mark_vaccinated: true,
    print: false,
  },
};
function CanScope(permission) {
  var currentScope = localStorage.getItem("scope");
  let currentPermissions = permissions[currentScope];
  if (
    !currentPermissions ||
    currentPermissions == null ||
    currentPermissions === undefined
  ) {
    return false;
  }

  return currentPermissions[permission];
}
export default CanScope;
