import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { ConfigContext } from "./context/ConfigContext";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import Auth from "./helpers/Auth";

function App() {
  const [config, setConfig] = useState({})
  let isAuthenticated = Auth.getAuth();

  useEffect(() => {
    const _getConfig = async () => {
        const result = await axios.get(process.env.REACT_APP_CONFIG_JSON_FILE);
        setConfig(result.data.client)
    }
    _getConfig()
  }, [setConfig])


  if (isAuthenticated === undefined) {
    return (
      <div className="App">
        <ConfigContext.Provider value={{ config }}>
          <Router>
            <Switch>
              <Route
                path="/auth/*"
                render={(props) => <AuthLayout {...props} />}
              />
              <Route path="*">
                <Redirect to="/auth/login" />
              </Route>
            </Switch>
          </Router>
        </ConfigContext.Provider>
      </div>
    );
  } else {
    return (
      <div className="App">
        <ConfigContext.Provider value={{ config }}>
        <Router>
          <Switch>
            <Route
              path="/admin/*"
              render={(props) => <AdminLayout {...props} />}
            />
            <Route path="*">
              {/* <Redirect to="/admin/index" /> */}
              <Redirect to="/admin/tables" />
            </Route>
          </Switch>
        </Router>
        </ConfigContext.Provider>
      </div>
    );
  }
}

export default App;
