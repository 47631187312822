import React from "react";
import { ConfigContext } from "../../context/ConfigContext"

const CredHeader = (props) => {
  const { config } = React.useContext(ConfigContext);
  return (
    <>
      <div
        className="header pb-7 pt-3 pt-lg-7 d-flex align-items-center"
        style={{
          minHeight: "0px",
          backgroundImage:
            "url(" +
            require("../../assets/img/theme/creds-cover.jpg").default +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className={`mask opacity-8`} style={{ background: config.headerBgColor }} />
        {/* Header container */}
        {/* <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col>
              <h1 className="display-2 text-white">
                {props.heading ? props.heading : "Issue ZADA COVIDPass"}
              </h1>
            </Col>
          </Row>
        </Container> */}
      </div>
    </>
  );
};

export default CredHeader;
