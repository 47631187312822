export const shared_info = {
    modal_contactus_heading: "Do you have any questions?",
    modal_contactus_phone_label: "Phone : ",
    modal_contactus_phone_value: "+95(0)9765606651",
    modal_contactus_email_label: "Email : ",
    modal_contactus_email_value: "help@zada.io",
    modal_contactus_chat_label: "Live chat : ",
    modal_contactus_chat_value: "m.me/zadamyanmar",
    button_close: "Close",
    welcome_text: "Welcome!",
    logout_text: "Logout"
}