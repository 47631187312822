import React, { useState, useEffect } from "react";
import CredHeader from "./../components/Headers/CredHeader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import Auth from "./../helpers/Auth";
import "react-toastify/dist/ReactToastify.css";
import companies from "helpers/Companies";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import axios from 'axios'
import { parsePhoneNumber, parse } from 'libphonenumber-js';
import PrintComponent from './PrintComponent';
import './BookingForm.css';
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  Dropdown,
  Container,
  Button,
  Row,
  Input,
  CardBody,
  Col,
  Label,
  Form,
  Modal,
  Table,
} from "reactstrap";
const fetch = require("node-fetch");
const moment = require("moment");
const animatedComponents = makeAnimated();

function BookingForm() {

  const timeSlotsArray = [
    "12:00AM - 1:00AM",
    "1:00AM - 2:00AM",
    "2:00AM - 3:00AM",
    "3:00AM - 4:00AM",
    "4:00AM - 5:00AM",
    "5:00AM - 6:00AM",
    "6:00AM - 7:00AM",
    "7:00AM - 8:00AM",
    "8:00AM - 9:00AM",
    "9:00AM - 10:00AM",
    "10:00AM - 11:00AM",
    "11:00AM - 12:00PM",
    "12:00PM - 1:00PM",
    "1:00PM - 2:00PM",
    "2:00PM - 3:00PM",
    "3:00PM - 4:00PM",
    "4:00PM - 5:00PM",
    "5:00PM - 6:00PM",
    "6:00PM - 7:00PM",
    "7:00PM - 8:00PM",
    "8:00PM - 9:00PM",
    "9:00PM - 10:00PM",
    "10:00PM - 11:00PM",
    "11:00PM - 12:00AM",
  ];


  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("Male");
  const [fullname, setFullName] = useState("");
  const [fathername, setFathername] = useState("");
  const [genderDrop, setGenderDrop] = useState(false);
  const [dob, setDob] = useState(new Date());
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+95");
  const [permanentaddress, setPermanentAddress] = useState("");
  const [township, setTownship] = useState("");
  const [state, setState] = useState("");
  const [paymentStatusDrop, setPaymentStatusDrop] = useState(false);
  const [paymentstatus, setPaymentStatus] = useState("Unpaid");
  const [vaccinationcity, setVaccinationCity] = useState("");
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [bookeddate, setBookedDate] = useState(moment().format("YYYY-MM-DD"));
  const [bookedslot, setBookedSlot] = useState("12:00AM - 1:00AM");
  const [bookedSlotDrop, setBookedSlotDrop] = useState(false);
  const [registrationStatusDrop, setRegistrationStatusDrop] = useState(false);
  const [registrationstatus, setRegistrationStatus] = useState("Arrived");
  const [vaccinationstatus, setVaccinationStatus] = useState("Not Vaccinated");
  const [vaccinationStatusDrop, setVaccinationStatusDrop] = useState(false);
  const [company, setCompany] = useState("");
  const [companyArray, setCompanyArray] = useState([]);
  const [nationality, setNationality] = useState("Myanmar");
  const [atcCode, setAtcCode] = useState("");
  const [holderName, setHolderName] = useState("");
  const [virusName, setVirusName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [markVoid, setMarkVoid] = useState(false);
  const [passport, setPassport] = useState("");
  const [mccocId, setMccocId] = useState("");
  const [products, setProducts] = useState();
  const [centerList, setCenters] = useState();
  const [spinnerCheck, setSpinnerCheck] = useState(false);
  const [vaccineArray, setVaccineArray] = useState([])
  const [vaccineIdArray, setVaccineIDArray] = useState([])
  const [vaccineTableArray, setVaccineTableArray] = useState([])
  const [centerVal, setCenterVal] = useState('')
  const [newProductPrice, setNewProductPrice] = useState()
  let vaccinationList;

  useEffect(() => {
    getProducts();
    getCenters();
  }, [])

  useEffect(() => {
    let array = [];
    for (let i = 0; i < companies.length; i++) {
      var obj = {
        label: companies[i],
        value: companies[i],
      };
      array.push(obj);
    }
    setCompanyArray(array);
  }, []);
  const signout = () => {
    Auth.signout();
    window.location.reload();
  };
  const clearForm = () => {
    setFullName("");
    setFathername("");
    setGender("Male");
    setDob("");
    setEmail("");
    setPhone("+95");
    setPermanentAddress("");
    setTownship("");
    setState("");
    setNationality("Myanmar");
    setPassport("");
    setMccocId("");
    setEmployeeId("");
    setCompany("Yoma Bank");
  };

  const searchNRC = async (e) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      if (!passport) {
        return toast.error(`Please input NRC or Passport number`);
      }
      let token = Auth.getToken();
      if (token === undefined) {
        signout();
      } else {
        var API_PATH =
          process.env.REACT_APP_API_URL + `/api/getBookingByPassport`;
        const response = await axios({
          url: API_PATH,
          params: { val: passport },
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response) {
          if (response.status === 200) {
            setFullName(response.data.bookingDetails.fullname)
            setDob(response.data.bookingDetails.dob)
            setPhone(response.data.bookingDetails.phone)
            setEmail(response.data.bookingDetails.email)
            setState(response.data.bookingDetails.state)
            setTownship(response.data.bookingDetails.township)
            setPermanentAddress(response.data.bookingDetails.permanentaddress)
          }
        }
      }
    }
  }

  const getInvoiceNumber = () => {
    //generating invoice number with 6 random alpha-num numbers plus current date milliseconds
    const d = new Date();
    let ms = d.getMilliseconds();
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = 4; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    // setInvoiceNo(result + ms.toString())
    return result + ms.toString()
  }

  /**
   * Get products from DB
   */
  const getProducts = async () => {
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      var API_PATH =
        process.env.REACT_APP_API_URL + `/api/products`;
      const response = await axios({
        url: API_PATH,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.products) {
        setProducts(response.data.products);
      }
    }
  }

  /**
   * Get centers from DB
   */
  const getCenters = async () => {
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      var API_PATH =
        process.env.REACT_APP_API_URL + `/api/centers`;
      const response = await axios({
        url: API_PATH,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.centerList) {
        setCenters(response.data.centerList);
      }
    }
  }

  /**
   * Selected vaccinations from dropdown
   */
  let selectedValue;
  let selectedID;
  let selectedValeAndPrice;
  const manageSelectedVaccines = (selectedVaccines) => {
    selectedValue = [];
    selectedID = [];
    selectedValeAndPrice = [];
    if (selectedVaccines) {
      for (let i = 0; i < selectedVaccines.length; i++) {
        selectedValue.push(selectedVaccines[i].value.split('=')[0])
        selectedID.push(selectedVaccines[i].value.split('=')[1])
        selectedValeAndPrice.push({
          "name": selectedVaccines[i].value.split('=')[0],
          "price": selectedVaccines[i].value.split('=')[2]
        })
      }
    }
    setVaccineArray(selectedValue)
    setVaccineIDArray(selectedID)
    setVaccineTableArray(selectedValeAndPrice)
  }


  const manageSelectedCenters = (selectedCenter) => {
    let selectedValue = ""
    if (selectedCenter) {
      selectedValue = selectedCenter.value
    }
    setCenterVal(selectedValue)
  }

  function randomCode(length) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  function getMyMessage(data) {
    const image = localStorage.getItem('image');
    var CurrentDate = moment().format("ddd DD-MM-YYYY HH:mm");
    let totalPrice = 0;
    const myMsg = () => (
      <div className="invoice-box">
        <table cellpadding="0" cellspacing="0">
          <tr className="top">
            <td colspan="2">
              <table>
                <tr>
                  <td className="title">
                    <img style={{ maxHeight: '10rem', maxWidth: '100%' }} src={image} alt='...' />
                  </td>

                  <td>
                    Invoice #: {data[0].invoiceNo}<br />
                    Created: {CurrentDate}<br />
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr className="information">
            <td colspan="2">
              <table>
                <tr>
                  <td>
                    {data[0].fullname}<br />
                  </td>

                  <td>
                    {data[0].permanentaddress}<br />
                    {data[0].township} <br />
                    {data[0].state}<br />
                    {data[0].phone}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr className="heading">
            <td>Item</td>

            <td>Price</td>
          </tr>

          {data.map((item, i) => {
            totalPrice += parseInt(item.productPrice);
            return <tr className="item" key={i} value={item}>
              <td>{item.vaccine}</td>
              <td>{item.productPrice}</td>
            </tr>;
          })
          }



          <tr className="total">
            <td></td>

            <td>Total: ${totalPrice}</td>
          </tr>
        </table>
        <PrintComponent data={data} />
      </div>
    )
    return myMsg
  }

  const submitBooking = async (e) => {
    e.preventDefault();
    if (vaccineArray) {
      const appointmentId = randomCode(9)
      let resultId = undefined;
      for (let i = 0; i < vaccineArray.length; i++) {
        resultId = await makeBooking(vaccineArray[i], appointmentId, vaccineIdArray[i], vaccineTableArray[i].price)
      }

      if (resultId !== undefined) {
        //Get booking by appointment for sending print data
        let token = Auth.getToken();
        var API_PATH_PRINT = `${process.env.REACT_APP_API_URL}/api/filter_bookings?appointmentId=${resultId}`;
        fetch(API_PATH_PRINT, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === 200) {
              toast(getMyMessage(json.bookings), {
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => window.location.href = "/admin/tables"
              });
            }
          });
      }
    }
  }
  const makeBooking = async (vaccineValue, appointmentId, vaccineId, vaccinePrice) => {
    let dobRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    let token = Auth.getToken();
    if (token === undefined) {
      signout();
    } else {
      try {
        let productPrice = ''
        var API_PATH_PRD =
          process.env.REACT_APP_API_URL + `/api/getProductByID`;
        const response = await axios({
          url: API_PATH_PRD,
          params: { prodID: vaccineId },
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response.data.prodObj) {
          productPrice = response.data.prodObj.price;
        }
        var data = {
          fullname,
          company,
          employeeId,
          nationality,
          dob,
          gender,
          phone,
          email,
          fathername,
          permanentaddress,
          mccocId,
          township,
          state,
          center: centerVal,
          paymentstatus,
          passport,
          vaccinationcity,
          bookeddate: bookeddate,
          bookedslot,
          registrationstatus,
          dose: "",
          vaccinationstatus,
          vaccine: vaccineValue,
          productID: vaccineId,
          productPrice: vaccinePrice ? vaccinePrice : productPrice,
          markVoid,
          invoiceNo: getInvoiceNumber(),
          appointmentId,
          virusName,
          marketingHolder: holderName,
          atcCode
        };
        if (fullname == null || fullname === undefined || fullname === "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Name Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (dob == null || dob === undefined || dob === "" || !dobRegex.test(dob)) {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Date of birth Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (vaccineValue == null || vaccineValue === undefined || vaccineValue === "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Vaccine selection Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (centerVal == null || centerVal === undefined || centerVal === "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Center selection Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (phone == null || phone === undefined || phone === "" || phone === "+") {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Phone Number Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          passport == null ||
          passport === undefined ||
          passport === ""
        ) {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`NRC/Passport Is Required`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (email !== "") {
          setSpinnerCheck(false)
          setIsLoading(false);
          const format =
            /(^(?!.*__.*)[a-z0-9]{2,253}(_?)[a-z0-9]+(?:\.[a-z0-9!#$%&*+\/=?^`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9]*[a-z0-9])?$)/gs;
          if (!email.match(format)) {
            return toast.error(`Invalid Email Format`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        try {
          const parsedPhoneNumber = parsePhoneNumber(phone);
          if (!parsedPhoneNumber.isValid()) {
            setSpinnerCheck(false)
            setIsLoading(false);
            return toast.error(`Phone Number Is Incorrect`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (e) {
          setSpinnerCheck(false)
          setIsLoading(false);
          return toast.error(`Phone Number Incorrect ${e.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setIsLoading(true);
        setSpinnerCheck(true);

        return new Promise((resolve, reject) => {
          var ISSUANCE_API_PATH =
            process.env.REACT_APP_API_URL + "/api/create-booking";
          fetch(ISSUANCE_API_PATH, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
            .then((res) => res.json())
            .then((json) => {
              if (json.status === 200) {
                /** Find the product and update the quantity */
                var PRODUCT_API_PATH =
                  process.env.REACT_APP_API_URL + "/api/update-products";
                fetch(PRODUCT_API_PATH, {
                  method: "POST",
                  body: JSON.stringify({ vaccineId, deductStock: true, addStock: false }),
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
                }).then((res) => res.json)
                  .then((json) => {
                    console.log('found the document', json)
                  })
                /** updation completed */
                setSpinnerCheck(false)

                resolve(json.data.appointmentId)

                clearForm();
                setIsLoading(false);
              } else {
                setSpinnerCheck(false)
                setIsLoading(false);
                toast.error(`${json.message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                reject(undefined);
              }
            });
        })
      } catch (e) {
        console.log({ e });
        toast.error(e.message, {
          autoClose: false
        });
        setIsLoading(false);
      }
    }
  }

  const handleChange = (value, index) => {
    // // Make a shallow copy of the current `data`.
    const newArray = [...vaccineTableArray];

    // // Update the changed item.
    newArray[index] = {
      ...newArray[index],
      price: value
    }

    setVaccineTableArray(newArray);
  }

  vaccinationList = products && products.length > 0 ? products : 'Loading options...';
  return (
    <>
      <ToastContainer />
      <CredHeader heading={"Create New Booking"} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Booking Form</h3>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="8">
                    <Label
                      for="infoPassport"
                      style={{ fontSize: 'small', color: 'green' }}
                    >press ENTER or TAB to pre-load data if exist</Label>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">
                        NRC / Passport<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter Your NRC/Passport Here"
                        value={passport}
                        onChange={(e) => setPassport(e.target.value)}
                        onKeyDown={(e) => searchNRC(e)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">
                        Name (အမည်)<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="Enter Your Full Name Here"
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Father Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Father Name Here"
                        value={fathername}
                        onChange={(e) => setFathername(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Gender
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={genderDrop}
                              toggle={() => setGenderDrop(!genderDrop)}
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {gender}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem onClick={() => setGender("Male")}>
                                  Male
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setGender("Female")}
                                >
                                  Female
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setGender("Other")}
                                >
                                  Other
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Date of birth<span className="text-danger">*</span></Label>
                      <Input
                        type="date"
                        placeholder="Enter Your Father Name Here"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Email (အီးမေးလ်)</Label>
                      <Input
                        type="email"
                        placeholder="Enter Your Email Here"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">
                        Phone Number (ဖုန်းနံပါတ်)
                        <span className="text-danger">*</span>
                      </Label>
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        country={"mm"}
                        value={phone}
                        onChange={(e) => {
                          setPhone("+" + e);
                        }}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Permanent Address</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Address Here"
                        value={permanentaddress}
                        onChange={(e) => setPermanentAddress(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Township</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Township Here"
                        value={township}
                        onChange={(e) => setTownship(e.target.value)}
                      />
                    </Col>

                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">State</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your State Here"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Nationality</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your State Here"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                      />
                    </Col>

                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Payment Status
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={paymentStatusDrop}
                              toggle={() =>
                                setPaymentStatusDrop(!paymentStatusDrop)
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {paymentstatus}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() => setPaymentStatus("Paid")}
                                >
                                  Paid
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => setPaymentStatus("Unpaid")}
                                >
                                  Unpaid
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Center Name
                            <span className="text-danger">*</span>
                          </label>
                          <div id="f5">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={false}
                              isLoading={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name="centerList"
                              options={centerList}
                              onChange={(e) => manageSelectedCenters(e)}
                            />
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>

                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-vaccinename"
                        >
                          Vaccine
                          <span className="text-danger">*</span>
                        </label>
                        <div id="input-eventtype">
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={vaccinationList}
                            onChange={(e) => manageSelectedVaccines(e)}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    {
                      vaccineTableArray && vaccineTableArray.length ?
                        <Row style={{ paddingTop: "2rem", paddingBottom: "2rem", width: "100%" }}>
                          <Col xl="12" md="12" sm="12">
                            <Table bordered={true} hover={true} responsive={true}>
                              <thead>
                                <tr><th><h3>#</h3></th><th><h3>Vaccine Name</h3></th><th><h3>Price</h3></th></tr>
                              </thead>
                              <tbody>
                                {
                                  vaccineTableArray.map((item, i) => {
                                    return (
                                      <tr>
                                        <th scope="row" style={{ verticalAlign: "middle", fontSize: "1rem" }}> {i + 1} </th>
                                        <td style={{ verticalAlign: "middle", fontSize: "1rem" }}> {item.name} </td>
                                        <td>
                                          <Input
                                            type="text"
                                            // value={item.price}
                                            onChange={(e) => handleChange(e.target.value, i)}
                                            defaultValue={item.price}
                                          />
                                        </td>
                                      </tr>
                                    )

                                  })
                                }
                              </tbody>
                            </Table>
                          </Col>
                        </Row> : ''
                    }
                    {/* <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-vaccinename"
                        >
                          Company
                        </label>

                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={{ label: company, value: company }}
                          options={companyArray}
                          onChange={(e) => {
                            setCompany(e.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Employee Id</Label>
                      <Input
                        type="text"
                        placeholder="Enter Your Employee Id"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                      />
                    </Col> */}
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <Label for="exampleEmail">Booked Date</Label>
                      <Input
                        type="date"
                        placeholder="Enter Your Booked Date"
                        value={bookeddate}
                        min={currentDate}
                        onChange={(e) => setBookedDate(e.target.value)}
                      />
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <label className="input-dose" htmlFor="f5">
                          Time Slot
                        </label>
                        <div id="f5">
                          <Dropdown
                            isOpen={bookedSlotDrop}
                            toggle={() => setBookedSlotDrop(!bookedSlotDrop)}
                          >
                            <DropdownToggle
                              color="white"
                              caret
                              className="dropdown"
                            >
                              {bookedslot}
                            </DropdownToggle>
                            <DropdownMenu
                              className="menu-dropdown"
                              style={{ overflow: "auto", maxHeight: "25vh" }}
                            >
                              {timeSlotsArray.map((slot, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() => setBookedSlot(slot)}
                                >
                                  {slot}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Registration Status
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={registrationStatusDrop}
                              toggle={() =>
                                setRegistrationStatusDrop(
                                  !registrationStatusDrop
                                )
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {registrationstatus}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() =>
                                    setRegistrationStatus("Arrived")
                                  }
                                >
                                  Arrived
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    setRegistrationStatus("Pre-booking")
                                  }
                                >
                                  Pre-booking
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    setRegistrationStatus("Phone-booking")
                                  }
                                >
                                  Phone-booking
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col xl="6" md="6" sm="12" className="pt-2">
                      <FormGroup>
                        <FormGroup>
                          <label className="input-dose" htmlFor="f5">
                            Vaccinated Status
                          </label>
                          <div id="f5">
                            <Dropdown
                              isOpen={vaccinationStatusDrop}
                              toggle={() =>
                                setVaccinationStatusDrop(!vaccinationStatusDrop)
                              }
                            >
                              <DropdownToggle
                                color="white"
                                caret
                                className="dropdown"
                              >
                                {vaccinationstatus}
                              </DropdownToggle>
                              <DropdownMenu className="menu-dropdown">
                                <DropdownItem
                                  onClick={() =>
                                    setVaccinationStatus("Vaccinated")
                                  }
                                >
                                  Vaccinated
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    setVaccinationStatus("Not Vaccinated")
                                  }
                                >
                                  Not Vaccinated
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-auto" md="4">
                      <Button
                        className="btn btn-success btn-md btn-block"
                        onClick={submitBooking}
                        disabled={isLoading}
                        type="button"
                      >
                        {!spinnerCheck ? ("Submit Record") : <div>Submitting Record  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}

                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default BookingForm;
